import { FormLabel } from "./FormLabel"
import { extendTheme } from "@chakra-ui/react"
import { Input } from "./Input"
import { Checkbox } from "./Checkbox"
import { Button } from "./Button"
import { Select } from "./Select"

export const theme = extendTheme({
    fonts: {
        body: "Roboto",
    },
    radii: {
        md: "12px",
    },
    colors: {
        bitter: {
            main: "#FF7650",
            bright: "#FFD1C5",
            light: "#FFAA93",
            shade: "#92442E",
            dark: "#5C2B1D",
        },
        zodiac: {
            main: "#101F44",
            shade: "#1E2F5A",
            dark: "#030C1D",
        },
    },
    components: {
        Input,
        Checkbox,
        Button,
        Select,
        FormLabel,
    },
})
