import { createMultiStyleConfigHelpers, defineStyle } from "@chakra-ui/react"
import { inputAnatomy } from "@chakra-ui/anatomy"

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(inputAnatomy.keys)

const baseStyle = definePartsStyle((props) => ({
    label: {
        fontSize: "10px",
    },
    field: {
        minHeight: "56px",
        color: "gray.800",
        background: "gray.50",
        borderColor: "zodiac.dark",
    },
    addon: {
        bg: "gray.100",
        minHeight: "56px",
        borderColor: "zodiac.dark",
        background: "white",
    },
}))

export const Input = defineMultiStyleConfig({ baseStyle })
