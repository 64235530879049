import { localStorage } from '../localStorage';
import { ParsedUrlQuery } from 'querystring';

let options: UseAnalyticsOptions = {
    silent: true,
    disabled: false,
};

export type SourceAttribution = {
    Source: string | string[];
    Medium: string | string[];
    Campaign: string | string[];
    ClickId?: string | string[];
};

type UseAnalyticsOptions = {
    silent?: boolean;
    disabled?: boolean;
};

function getBasePayload() {
    return {
        url: new URL(window.location.href),
        page: window.location.pathname,
        referrer: document.referrer,
        timeFromFirstPageview: getTimeFromFirstPageview(),
        // Lead Source Attributions
        First: localStorage.get('first-source-attribution'),
        Last: localStorage.get('last-source-attribution'),
    };
}

function getTimeFromFirstPageview() {
    const firstPageviewTime = localStorage.get('first-pageview-time-ms');
    if (firstPageviewTime) {
        return Date.now() - firstPageviewTime;
    } else {
        return 0;
    }
}

export function setSourceAttribution(data: ParsedUrlQuery) {
    let attribution = getSourceAttribution(data);
    if (!!localStorage.get('first-source-attribution')) {
        localStorage.set('last-source-attribution', attribution);
        return;
    }
    localStorage.set('first-source-attribution', attribution);
}

function getSourceAttribution(data: ParsedUrlQuery): SourceAttribution {
    const { referrer } = document;
    return {
        Source: data.utm_source ?? getSource(referrer),
        Medium: data.utm_medium ?? getMedium(referrer),
        Campaign: data?.utm_campaign ?? '',
        ClickId: data?.click_id ?? '',
    };
}

function getMedium(referrer: string) {
    const SOURCE_MAP = ['google', 'bing', 'duckduckgo', 'yahoo', 'ask', 'aol', 'yandex'];
    return SOURCE_MAP.some((item) => referrer.includes(item)) ? 'organic' : 'referral';
}

function getSource(referrer: string) {
    return referrer || 'direct';
}
