import { FormValues } from 'pages/qualified-lead-form';
import { SourceAttribution } from '../analytics';

export type LocalStorageKeys = {
    routerQuery: Record<string, string | string[] | undefined>;
    'deal-id': number;
    'first-pageview-time-ms': number;
    'homepage-form': {
        licensePlate: string;
        carFinancialState: 'paid' | 'financed';
    };
    'financed-form-step-2': {
        name: string;
        lastName: string;
        email: string;
        phone: string;
    };
    'paid-form-step-2': {
        name: string;
        lastName: string;
        email: string;
        phone: string;
    };
    'first-source-attribution': SourceAttribution;
    'last-source-attribution': SourceAttribution;
    'qualified-lead-form': {
        step: number;
        data: FormValues;
    };
};

function isLocalhost() {
    if (typeof window !== 'undefined') {
        return window.location.hostname === 'localhost';
    } else {
        return false;
    }
}

export const localStorage = {
    get: <K extends keyof LocalStorageKeys>(key: K): LocalStorageKeys[K] | undefined => {
        if (isLocalhost()) {
            console.log('get', key, JSON.parse(window.localStorage.getItem(key) ?? '{}'));
        }
        if (typeof window === 'undefined') {
            throw new Error('localStorage is not available on server side');
        }
        const value = window.localStorage.getItem(key);
        if (value) {
            return JSON.parse(value);
        } else {
            return undefined;
        }
    },
    set: <K extends keyof LocalStorageKeys>(key: K, value: LocalStorageKeys[K]): void => {
        if (isLocalhost()) {
            console.log('set', key, value);
        }
        if (typeof window === 'undefined' ||!window.localStorage) {
            throw new Error('localStorage is not available on server side');
        }
        return window.localStorage.setItem(key, JSON.stringify(value));
    },
    update: <K extends keyof LocalStorageKeys>(key: K, value: Partial<LocalStorageKeys[K]>): void => {
        if (isLocalhost()) {
            console.log('update', key, value);
        }
        if (typeof window === 'undefined') {
            throw new Error('localStorage is not available on server side');
        }
        const currentValue = JSON.parse(window.localStorage.getItem(key) || '{}');
        return window.localStorage.setItem(key, JSON.stringify({ ...currentValue, ...value }));
    },
    remove: <K extends keyof LocalStorageKeys>(key: K): void => {
        if (isLocalhost()) {
            console.log('remove', key);
        }
        if (typeof window === 'undefined') {
            throw new Error('localStorage is not available on server side');
        }
        return window.localStorage.removeItem(key);
    },
};
