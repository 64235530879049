export const FormLabel = {
    baseStyle: {
        fontSize: "1rem",
    },
    screen: {
        sm: {
            baseStyle: {
                fontSize: "2rem",
            },
        },
        md: {
            baseStyle: {
                fontSize: "2rem",
            },
        },
    },
}
