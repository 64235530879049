import { buttonAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle, defineStyleConfig } from '@chakra-ui/react';

const { definePartsStyle } = createMultiStyleConfigHelpers(buttonAnatomy.keys);

const baseStyle = definePartsStyle((props) => ({
    baseStyle: {
        color: 'gray.800',
        bg: 'white',
        background: 'white',
        padding: '2rem',
    },
}));

const primary = defineStyle({
    background: 'linear-gradient(90deg, #FF7650 38.27%, #FEFCD6 221.43%)',
    fontWeight: '700',
    color: 'white',
    fontSize: '18px',
    padding: '1.6rem',
    maxWidth: '100%',
    whiteSpace: 'nowrap',
    rounded: 'xl',
    _hover: {
        transform: 'scale(1.008)',
        _disabled: {
            background: 'gray.800',
            transform: 'scale(1)',
        },
    },
    _disabled: {
        background: 'gray.800',
        color: 'gray.500',
    },
});

const black = defineStyle({
    background: '#00202A',
    color: 'white',
    fontWeight: '700',
    fontSize: '18px',
    padding: '1.6rem',
    maxWidth: '100%',
    rounded: 'xl',
    whiteSpace: 'normal',
    _hover: {
        background: '#030c1d',
    },
});

const empty = defineStyle({
    padding: '0',
    border: '0',
    rounded: 'none',
    _hover: {
        background: 'rgba(0, 0, 0, 0.04)',
    },
});

export const Button = defineStyleConfig({
    baseStyle,
    variants: {
        primary,
        black,
        empty,
    },
});
