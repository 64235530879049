import { createContext, Dispatch, PropsWithChildren, SetStateAction, useMemo, useState } from "react"
import { PreOffer } from "@/types/forms/preOffer"
import { useBoolean } from "@/hooks/useBoolean"

type AppContextInterface = {
    isReconfigurePreOfferOpen: boolean
    preOffer?: PreOffer
    setPreOffer: Dispatch<SetStateAction<PreOffer | undefined>>
    reconfigureOfferRequestedAmount?: number
    setReconfigureOfferRequestedAmount: Dispatch<SetStateAction<number | undefined>>
    toggleReconfigurePreOffer(): void
}

export const AppContext = createContext<AppContextInterface | undefined>(undefined)

export function AppProvider(props: PropsWithChildren) {
    const [preOffer, setPreOffer] = useState<PreOffer>()
    const [reconfigureOfferRequestedAmount, setReconfigureOfferRequestedAmount] = useState<number>()
    const [isReconfigurePreOfferOpen, , , toggleReconfigurePreOffer] = useBoolean()

    const value = useMemo(
        () => ({
            isReconfigurePreOfferOpen,
            preOffer,
            setPreOffer,
            reconfigureOfferRequestedAmount,
            setReconfigureOfferRequestedAmount,
            toggleReconfigurePreOffer,
        }),
        [isReconfigurePreOfferOpen, preOffer, reconfigureOfferRequestedAmount, toggleReconfigurePreOffer]
    )

    return <AppContext.Provider {...props} value={value} />
}
