import { createMultiStyleConfigHelpers, defineStyle, defineStyleConfig } from "@chakra-ui/react"
import { selectAnatomy } from "@chakra-ui/anatomy"

const { definePartsStyle } = createMultiStyleConfigHelpers(selectAnatomy.keys)

const baseStyle = definePartsStyle((props) => ({
    field: {
        background: "white",
        color: "gray.800",
        height: "100px",
        borderColor: "zodiac.dark",
        size: "8",
        minHeight: "56px",
    },
}))

export const Select = defineStyleConfig({
    baseStyle,
})
