import { useCallback, useState } from 'react';

type UseBoolean = [boolean, () => void, () => void, () => void];

const useBoolean = (initialState = false): UseBoolean => {
  const [state, setState] = useState<boolean>(initialState);

  const setTrue = useCallback(() => {
    setState(true);
  }, []);

  const setFalse = useCallback(() => {
    setState(false);
  }, []);

  const toggle = useCallback(() => {
    setState((state) => !state);
  }, []);

  return [state, setTrue, setFalse, toggle];
};

export type { UseBoolean };
export { useBoolean };
