import { createMultiStyleConfigHelpers } from "@chakra-ui/react"
import { checkboxAnatomy } from "@chakra-ui/anatomy"

const { definePartsStyle, defineMultiStyleConfig } =
    createMultiStyleConfigHelpers(checkboxAnatomy.keys)

const baseStyle = definePartsStyle((props) => ({
    control: {
        color: "gray.800",
        bg: "gray.100",
        background: "white",
        borderColor: "gray.700",
        border: "1px solid",
        borderRadius: "4px",
        height: "22px",
        width: "22px",
        _hover: {
            borderColor: "gray.800", // FIXME: This doesn't work
        },
        _focus: {
            borderColor: "gray.800",
        },
    },
}))

export const Checkbox = defineMultiStyleConfig({ baseStyle })
